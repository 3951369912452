import { Injectable } from "@angular/core";
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ServiceStepModel } from "../models";
import { ServiceItem, NavigationExtras } from "../models/interface";

@Injectable()
export class ServiceCase {
  private serviceUrl = `${environment.apiUrl}/service`;
  private assetUrl = `${environment.apiUrl}/asset`;
  
  constructor(private http: HttpClient) {}

  createNavServiceItem(scItem): Observable<any> {
    return this.http.post(`${this.serviceUrl}/store/service-item/nav`, scItem)
    .pipe(catchError((error: any) => throwError(error)));
  }

  saveRegularServiceItem(scItem): Observable<any> {
    return this.http.post(`${this.serviceUrl}/store/service-item`, scItem)
    .pipe(catchError((error: any) => throwError(error)));
  }

  createServiceCase(serviceCase, serialNumber: string): Observable<any> {
    return this.http.post(`${this.serviceUrl}/desk/service-device?serial_number=${serialNumber}`, serviceCase)
    .pipe(catchError((error: any) => throwError(error)));
  }

  updateServiceCase(serviceCase, serviceCaseId): Observable<any> {
    return this.http.patch(`${this.serviceUrl}/desk/update-service-case/${serviceCaseId}`, serviceCase)
    .pipe(catchError((error: any) => throwError(error)));
  }

  updateServiceCustomer(serviceCustomer, scID?): Observable<any> {
    let params = new HttpParams();
    
    if (scID) {
      params = params.set('service_case_id', scID);
    }
  
    return this.http.post(`${this.serviceUrl}/desk/update-service-customer`, serviceCustomer, { params })
    .pipe(catchError((error: any) => throwError(error)));
  }

  set serviceItem(scItem) {
      sessionStorage.setItem('__scItem', JSON.stringify(scItem));
  }

  get serviceItem() {
      return JSON.parse(sessionStorage.getItem('__scItem')) as ServiceItem;
  }

  removeServiceItem() {
    sessionStorage.removeItem('__scItem');
  }

  set navigationExtras(extras) {
    sessionStorage.setItem('__navigationExtras', JSON.stringify(extras));
  } 

  get navigationExtras() {
      return JSON.parse(sessionStorage.getItem('__navigationExtras')) as NavigationExtras
  }

  removeNavigationExtras() {
    sessionStorage.removeItem('__navigationExtras');
  }

  searchCustomerByName(customer_name: string): Observable<any> {
    return this.http.get(`${this.serviceUrl}/desk/search-nav-customer?customer_name=${customer_name}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getServiceCoverage(serviceCoverageData): Observable<any> {
    return this.http.post(`${this.serviceUrl}/desk/coverage-matrix`, serviceCoverageData)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getServiceStepPDFOffer(company_id: number, device_id: number, service_case_id: number, document_type: number): Observable<any>{
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/${device_id}/service-case/${service_case_id}/pdf?document_type=${document_type}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  bookServiceCase(service_case_id: number, receipt_number: string): Observable<any>{
    return this.http.post(`${this.serviceUrl}/desk/book-service-case?service_case_id=${service_case_id}`, {receipt_number})
    .pipe(catchError((error: any) => throwError(error)));
  }

  completeServiceCaseStep(service_case_id: number, step: ServiceStepModel, resolution?: string): Observable<any>{
    return this.http.post(`${this.serviceUrl}/desk/complete-sc-step?service_case_id=${service_case_id}`, {serviceStep: step, resolution: resolution})
    .pipe(catchError((error: any) => throwError(error)));
  }

  sendServiceOrderEmail(service_case_id: number): Observable<any>{
    return this.http.post(`${this.serviceUrl}/desk/send-service-email?service_case_id=${service_case_id}`,{})
    .pipe(catchError((error: any) => throwError(error)));
  }
 
    getAvailableDocuments(company_id: number, device_id: number, service_case_id: number): Observable<any>{
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/${device_id}/service-case/${service_case_id}/available-documents`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getNavTypes(): Observable<any>{
    return this.http.get(`${this.serviceUrl}/desk/nav-types`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  exportDeviceServiceCaseSummary(company_id: number): Observable<any>{
    return this.http.get(`${this.serviceUrl}/desk/device-sc-summary?company_id=${company_id}`)
    .pipe(catchError((error: any) => throwError(error)));
  }
}
