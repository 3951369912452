import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ToastrModule } from "ngx-toastr";
import { NgxFileDropModule } from 'ngx-file-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatNativeDateModule } from "@angular/material/core";
import { AgmCoreModule } from "@agm/core";
// import { AppDateAdapter, APP_DATE_FORMATS } from "./shared/helpers/format-datepicker";


// ANGULAR MATERIAL
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Material Navigation
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
// Material Layout
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
// Material Buttons & Indicators
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
// Material Popups & Modals
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
// Material Data tables
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatMomentDateModule
  ],
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  declarations: []
})
export class MaterialModule {}

import { TokenInterceptor } from './shared/interceptors';

// ROUTING
import { AppRoutes } from "./app.routing";

// GUARDS AND SERVICES
import { 
  AuthGuard, 
  AuthenticationService, 
  UserService, 
  ServiceManagerService,
  BranchService, 
  DeviceService, 
  SCDataService, 
  ServiceCase, 
  NotificationService, 
  ServiceItemMappingService,
  DeviceDiagnosticService,
  RolesGuard
} from "./shared";

// COMPONENTS
import { AppComponent } from "./app.component";
import { AuthenticationComponent } from "./authentication/authentication.component";
import { ServiceManagerComponent } from "./service-manager/service-manager.component";
import { NavbarComponent } from "./service-manager/navbar/navbar.component";
import { SidebarComponent } from "./service-manager/sidebar/sidebar.component";
import { DeleteModalComponent } from './shared/modal-dialogs/delete-modal/delete-modal.component';
import { ModifyDetailsComponent } from './shared/modal-dialogs/modify-details/modify-details.component';
import { InactiveModalComponent } from './shared/modal-dialogs/inactive-modal/inactive-modal.component';
import { BreadscrumbComponent } from './shared/breadscrumb/breadscrumb.component';
import { UploadModalComponent } from './shared/modal-dialogs/upload-modal/upload-modal.component';
import { ModifyInsuranceDetailsComponent } from './shared/modal-dialogs/modify-insurance-details/modify-insurance-details.component';
import { ModifyDeviceSpecComponent } from './shared/modal-dialogs/modify-device-spec/modify-device-spec.component';
import { RobotActionDialogComponent } from './shared/modal-dialogs/robot-action-dialog/robot-action-dialog.component';
import { UpdateSalesPersonComponent } from './shared/modal-dialogs/update-sales-person/update-sales-person.component';
import { AddDebitorModalComponent } from './shared/modal-dialogs/add-debitor-modal/add-debitor-modal.component';
import { EditDeviceDetailsComponent } from "./shared/components/edit-device-details/edit-device-details/edit-device-details.component";
import { TermsComponent } from './shared/modal-dialogs/terms/terms.component';
import { EditScDataComponent } from './shared/modal-dialogs/edit-sc-data/edit-sc-data.component';
import { EditScCustomerComponent } from './shared/modal-dialogs/edit-sc-customer/edit-sc-customer.component';

//PIPES
import { MainPipe } from './shared/pipes/main-pipe.module';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { ActionNeededDialogComponent } from "./shared/modal-dialogs/action-needed-dialog/action-needed-dialog.component";
import { DeviceActiveServiceComponent } from './shared/modal-dialogs/device-active-service/device-active-service.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    ServiceManagerComponent,
    NavbarComponent,
    SidebarComponent,
    DeleteModalComponent,
    ModifyDetailsComponent,
    InactiveModalComponent,
    BreadscrumbComponent,
    ModifyInsuranceDetailsComponent,
    ModifyDeviceSpecComponent,
    UploadModalComponent,
    ActionNeededDialogComponent,
    RobotActionDialogComponent,
    UpdateSalesPersonComponent,
    AddDebitorModalComponent,
    EditDeviceDetailsComponent,
    TermsComponent,
    DeviceActiveServiceComponent, 
    EditScDataComponent,
    EditScCustomerComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MainPipe,
    NgxFileDropModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCl-D6yMBYPVzkWuyA3aa3JZe9suJZrVeQ',
      libraries: ['places']
    })
  ],
  providers: [
    AuthGuard,
    RolesGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // { provide: DateAdapter, useClass: AppDateAdapter },
    // { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    AuthenticationService,
    UserService,
    ServiceManagerService,
    BranchService,
    DeviceService,
    SCDataService,
    ServiceCase,
    NotificationService,
    ServiceItemMappingService,
    DeviceDiagnosticService
  ],
  entryComponents: [
    DeleteModalComponent, 
    ModifyDetailsComponent, 
    InactiveModalComponent, 
    ModifyInsuranceDetailsComponent, 
    ModifyDeviceSpecComponent,
    UploadModalComponent,
    ActionNeededDialogComponent,
    RobotActionDialogComponent,
    UpdateSalesPersonComponent,
    AddDebitorModalComponent,
    EditDeviceDetailsComponent,
    TermsComponent,
    EditScDataComponent,
    EditScCustomerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
