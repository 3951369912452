<mat-dialog-content [formGroup]="scCustomerForm">
    <h2 class="remove-pu__header text-18">Customer</h2>
    <div class="divider"></div>
    <div class="form-row">
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
        </mat-form-field>
    </div>
    <div class="form-row">
        <mat-form-field appearance="outline" class="mr-1">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput formControlName="phone" />
        </mat-form-field>
    </div>
    <!-- <div class="form-row">
        <mat-form-field appearance="outline" class="mr-1">
            <mat-label>Address</mat-label>
            <input matInput formControlName="address" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Postal code</mat-label>
            <input matInput formControlName="postal_code" />
        </mat-form-field>
    </div> -->
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-dialog-close class="btn btn-outline-primary mr-1">Cancel</button>
    <button (click)="confirm()" class="btn btn-primary">Save</button>
</mat-dialog-actions>
