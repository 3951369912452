import { Injectable } from '@angular/core';
import { customerTypes } from '../..';

@Injectable()
export class ServiceItemMappingService {
    constructor() { }

    mapDevice(data: any): any {
        return {
          imei: data?.Device?.IMEI,
          serial_number: data?.Device?.SerialNumnber,
          type: data?.Device?.Type ? data?.Device?.Type : data?.Device?.category_2,
          brand: data?.Device?.Brand,
          model: data?.Device?.Model ? data?.Device?.Model : data?.Device?.category_3,
          description: data?.Device?.Description,
          external_id: data?.Device?.ItemNumber,
          sc_item_number: data?.Device?.ServiceItemNumber,
          product_category: data?.Device?.Product_Category,
          purchase_date: data?.Device?.PurchaseDate,
          color: data?.Device?.color,
          capacity: data?.Device?.memory_type
        };
    }
    
    mapCustomer(data: any): any {
        return {
          vat: customerTypes[data?.Company?.eCommerceType],
          vat_number: data?.Company?.VATNumber,
          name: data?.Company?.Name,
          address: data?.Company?.Address,
          zip: data?.Company?.PostalCode,
          // country: data?.Company?.City,
          country: data?.Company?.CountryCode ? data?.Company?.CountryCode : 'AT',
          city: data?.Company?.City ? data?.Company?.City : 'Wien',
          konzern_code: "",
          CustomerNo: data?.Company?.CustomerNo,
          unit_code: "",
          phone: data?.Company?.ContactPhone,
          email: data?.Company?.Email,
          postal_code: data?.Company?.PostalCode
        };
    }
    
    mapWarrantyInsurance(data: any): any {
        return {
          warranty: data?.warrantyStatusDescription ? data?.warrantyStatusDescription : data?.Device?.WarrantySupplier,
          warranty_date: {
            warranty_start_date: data?.registrationDate ? data?.registrationDate : data?.Device?.WarrantyStartDate,
            warranty_end_date: data?.Device?.WarrantyEndDate,
          },
          insurance: data?.Device?.InsuranceSupplier,
          insurance_date: {
            insurance_start_date: data?.Device?.InsuranceStartDate,
            insurance_end_date: data?.Device?.InsuranceEndDate,
          }
        };
    }

    mapPreviousSC(data: any): any | undefined {
      if (data?.PrevicousServiceCase) {
        return {
          case_number: data?.PrevicousServiceCase?.CaseNumber,
          cost: data?.PrevicousServiceCase?.Cost,
          date: data?.PrevicousServiceCase?.Date,
          repair_solution: data?.PrevicousServiceCase?.RepairSolution,
          short_description: data?.PrevicousServiceCase?.ShortDescription
         };
      } else undefined
        
    }

    mapGSXresponse(data: any): any | undefined {
      console.log(data);
      if (data) {
        const { configDescription, productDescription, identifiers, warrantyInfo, soldToName } = data.device || {};
        const { serial, imei } = identifiers || {};
        const { coverageStartDate, coverageEndDate, contractCoverageEndDate } = warrantyInfo || {};
        
        return data.serviceItem = {
                ...data.serviceItem,
                device: {
                    ...data.serviceItem?.device,
                    description: configDescription,
                    model: productDescription,
                    serial_number: serial,
                    imei: imei,
                    brand: 'Apple',
                    type: data.device_type,
                    seller: soldToName
                },
                warrantyInsurance: {
                    ...data.serviceItem?.warrantyInsurance,
                    warranty_date: {
                        warranty_start_date: coverageStartDate,
                        warranty_end_date: coverageEndDate,
                        warranty_contract_coverage_end_date: contractCoverageEndDate,
                    },
                },
            };
      } else undefined
    }
}