import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-sc-customer',
  templateUrl: './edit-sc-customer.component.html',
  styleUrls: ['./edit-sc-customer.component.scss']
})
export class EditScCustomerComponent implements OnInit {
  public scCustomerForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditScCustomerComponent>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.scCustomerForm = this.fb.group({
      name: [],
      email: [],
      phone: [],
       // address: [],
      // postal_code: [],
    })

    this.scCustomerForm.patchValue(this.data.customer);
  }


  confirm() {
    this.dialogRef.close(this.scCustomerForm.value);
  }

}
